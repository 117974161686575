<template>
   <Layout>
      <div class="d-flex justify-content-between align-items-center">
         <div class="d-flex page-title-box">
            <h4 class="mr-2 mb-0">View Touchpoint</h4>
         </div>
         <div class="page-title-box d-flex align-items-center justify-content-between">
            <div class="page-title-right">
               <span style="color: grey; cursor: pointer" @click="$router.go(-1)">Back</span>
            </div>
         </div>
      </div>
      <template v-if="hasData">
         <div style="height: 200vh">
            <iframe :src="getTouchpointAnalytics" frameborder="0" width="100%" height="100%" scrolling="no"></iframe>
         </div>
      </template>
      <template v-if="noData">
         <div style="height: 200px; width: 100%; font-size: 24px; font-weight: 700"
            class="d-flex justify-content-center align-items-center">
            <div style="display: grid">
               <img src="../../../../public/noData.svg" alt="" />
               <span style="font-size: 20px; font-weight: 700">No Content Available</span>
            </div>
         </div>
      </template>
   </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import touchpointMixin from "../../../mixins/ModuleJs/touchpoint";
import campaignMixin from "../../../mixins/ModuleJs/campaign";

export default {
   components: {
      Layout,
   },
   data() {
      return {
      }
   },
   mixins: [MixinRequest, touchpointMixin, campaignMixin],
   created() {
      this.getSingleTouchpoint();
   },
}

</script>