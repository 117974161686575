import {
  campaign
} from "../../js/path";

export default {
  data() {
    return {
      view_tab: "touchpoint",
      hasData: false,
      campaign_active_tab: 'all',
      getAnalytics: "",
      getOverallAnalytics: "",
      isOverall: false,
      isCampaignContent: false,
      isTouchpoint: true,
      campaign_current_page: 1,
      noData: false,
      showAllData: false,
      all_campaign_data: [],
      showData: false,
      campaign_id: null,
      table_header: [],
      params: "",
      key: 0,
      activeTab: "all",
      pageNo: null,
      fields: [{
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Campaign Name",
        },
        {
          key: "total_touch_points",
          label: "Touchpoints"
        },
        {
          key: "created_from",
          label: "Created From"
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        }
      ],
      tableData: [],
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      form: {
        name: ''
      },
    }
  },
  methods: {
    resetPagination() {
      this.campaign_current_page = 1;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    searchFor() {
      if (this.filter.length > 1) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } 
      else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } 
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.currentPage = 1;
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData(this.activeTab);
      } 
    },
    async fetchData(txt) {
      this.isPaginate = false;
      this.showData = false;
      let url = null;
      if (txt == "trash") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = campaign.campaignUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = campaign.campaignUrl + "?filter=" + txt;
        }
      } else if (txt == "all") {
        this.activeTab = txt;
        if (this.filter != null && this.filter != "") {
          url = campaign.campaignUrl + "?filter=" + txt + "&search=" + this.filter;
        } else {
          url = campaign.campaignUrl + "?filter=" + txt;
        }
      }
      url += this.params;
      this.$store.commit("loader/updateStatus", true);
      try {
        const data = await this.getRequest(url);
        this.showData = true;
        this.isPaginate = true;
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      this.key += 1;
    },
    async fetchCampaign(id) {
      this.$store.commit('loader/updateStatus', true);
      try {
        let url = campaign.campaignUrl + '/' + id;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.name != "" && responseData.name != null) {
            this.form.name = responseData.name;
          }
          if (responseData.id != "" && responseData.id != null) {
            this.campaign_id = responseData.id;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Fetching error!",
        });
      }
      this.$store.commit('loader/updateStatus', false);
    },
    async fetchAllCampaign(action_type, reset = true) {
      this.noData = false;
      if (reset == true) {
        this.resetPagination();
      }
      this.$store.commit('loader/updateStatus', true);
      let url = null;
      if (action_type == 'all') {
        this.campaign_active_tab = action_type;
        url = campaign.fetchSingleTouchpoint + "/" + this.$route.params.id + "?page=" + this.campaign_current_page;
      } else {
        this.campaign_active_tab = action_type;
        url = campaign.fetchSingleTouchpoint + "/" + this.$route.params.id + "?action_type=" + action_type + "&page=" + this.campaign_current_page;
      }
      const data = await this.getRequest(url);
      if (data.status) {
        this.all_campaign_data = [];
        this.all_campaign_data = data.response;
        if (this.all_campaign_data.count == 0) {
          this.noData = true;
          this.showAllData = false;
        } else {
          this.showAllData = true;
          this.noData = false;
        }
      }
      this.$store.commit('loader/updateStatus', false);
    },
    async submitData() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = campaign.campaignUrl;
        if (this.$route.name == "edit-campaign") {
          url = campaign.campaignUrl + "/" + this.campaign_id;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          dataAppend.append(key, this.form[key])
        }
        if (this.$route.name == "edit-campaign") {
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/campaign");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchAnalytics() {
      try {
        let url = campaign.analytics;
        const data = await this.getRequest(url);
        if (data.status) {
          this.hasData = true;
          this.noData = false;
          this.getAnalytics = data.url;
        } else {
          this.hasData = false;
          this.noData = true;
        }
      } catch (err) {
        this.noData = true;
      }
    },
    async fetchOverallAnalytics() {
      try {
        let url = campaign.analytics + "?campaign_id=" + this.campaignId;
        const data = await this.getRequest(url);
        if (data.status) {
          this.hasData = true;
          this.noData = false;
          this.getOverallAnalytics = data.url;
        } else {
          this.hasData = false;
          this.noData = true;
        }
      } catch (err) {
        this.noData = true;
      }
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-campaign")) {
          let index = this.fields.findIndex((item) => item.key == "restore");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-campaign")) {
            this.fields[4] = {
              key: "delete",
            };
          } else {
            this.fields[5] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-campaign")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[4] = {
              key: "edit",
            };
          }
        }
        if (!this.can("delete-campaign")) {
          let index = this.fields.findIndex((item) => item.key == "delete");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[5] = {
              key: "delete",
            };
          }
        }
      }
    },
    async deleteCampaign(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = campaign.campaignUrl + "/" + id;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreCampaign(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = campaign.restoreCampaign + "/" + id;
        const data = await this.postRequest(url, {});
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    checkTab(campaign_tab) {
      if (campaign_tab == 'overall') {
        this.view_tab = campaign_tab;
        this.fetchOverallAnalytics();
        this.isTouchpoint = false;
        this.isCampaignContent = false;
        this.isOverall = true;
      } else if (campaign_tab == 'touchpoint') {
        this.view_tab = campaign_tab;
        this.isTouchpoint = true;
        this.isCampaignContent = false;
        this.isOverall = false;
      } else if (campaign_tab == 'content') {
        this.view_tab = campaign_tab;
        this.fetchAllCampaign('all');
        this.isTouchpoint = false;
        this.isCampaignContent = true;
        this.isOverall = false;
      }
    },
    checkPermission() {
      if (this.can('communication')) {
        this.view_tab = 'touchpoint';
        this.isTouchpoint = true;
        this.isCampaignContent = false;
        this.isOverall = false;
      } else if (this.can('overall-analytics')) {
        this.view_tab = 'overall';
        this.fetchOverallAnalytics(this.campaign_id);
        this.isTouchpoint = false;
        this.isCampaignContent = false;
        this.isOverall = true;
      } else if (this.can('view-campaign-content')) {
        this.view_tab = 'content';
        this.fetchAllCampaign('all');
        this.isTouchpoint = false;
        this.isCampaignContent = true;
        this.isOverall = false;
      }
    }
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
    campaign_current_page: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.params = `&page=${value}`;
          this.fetchAllCampaign(this.campaign_active_tab, false);
        }
      }
    },
    activeTab(v) {
      if (v) {
        this.tabActive();
      }
    },
  },
  created() {
    if (this.$route.name == "add-campaign" || this.$route.name == "edit-campaign") {
      if (this.$route.name == "edit-campaign") {
        this.fetchCampaign(this.$route.params.id);
      }
    } else if (this.$route.name == "campaign") {
      this.fetchData(this.activeTab);
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      this.tabActive();
    } else if (this.$route.name == 'main-analytics') {
      this.fetchAnalytics();
    } else if (this.$route.name == 'view-single-campaign') {
      this.checkTab(this.view_tab);
      this.checkPermission();
    }
  },
}